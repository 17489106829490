<template>
    <div>
        <div class="filter-wrap" v-if="userData && userData.role === 'admin'">
            <div class="filter">
                <v-select v-model="catFilter" placeholder="Select Category" attach hide-details @input="getAllUserTasks()" :options="filterOptions"></v-select>
            </div>
            <div class="filter w300">
                <v-select v-model="practiceFilter" placeholder="Select Practice" label="name" attach hide-details @input="getAllUserTasks()" :options="practices"></v-select>
            </div>
        </div>
        <b-card v-if="totalTasks === 0" no-body class="mb-0">
            <div class="m-2 pb-4 text-center">There are no tasks yet.</div>
        </b-card>
        <b-card v-if="totalTasks > 0" no-body class="mb-0">
            <b-table :items="tasks" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <template #cell(name)="data">
                    <b-link v-if="data.item.task_type === 'practice'" :to="{name:'iab-other-practice',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'owner'" :to="{name:'iab-client-owner',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'client'" :to="{name:'iab-client',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'contractor'" :to="{name:'iab-contractor',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'staff'" :to="{name:'iab-staff',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <span v-else>{{ data.item.name }}</span>
                </template>
                <template #cell(type)="data">
                    <b-badge v-if="data.item.type === 'system'" variant="success">System</b-badge>
                    <b-badge v-if="data.item.type === 'manual'" variant="warning">Manual</b-badge>
                </template>
                <template #cell(priority)="data">
                    <b-badge v-if="data.item.priority === 'low'" variant="success">Low</b-badge>
                    <b-badge v-if="data.item.priority === 'medium'" variant="warning">Medium</b-badge>
                    <b-badge v-if="data.item.priority === 'high'" variant="danger">High</b-badge>
                </template>
                <template #cell(category)="data">
                    <span>{{ data.item.category }}</span>
                </template>
                <template #cell(attachment_url)="data">
                    <a :href="data.item.attachment_url" v-if="data.item.attachment_url">
                        <feather-icon icon="FileTextIcon" size="18"/>
                    </a>
                </template>
                <template #cell(actions)="data">
                    <b-button  v-if="userData && userData.role !== 'admin' && !data.item.completed" variant="primary" type="button" @click="handleTaskClick(data.item)">Complete</b-button>
                    <b-button  v-if="userData && userData.role === 'admin' && !data.item.reviewed" variant="primary" type="button" @click="handleTaskClick(data.item)">Complete</b-button>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalTasks" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18"/></template>
                            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
<script>
    import {BCard,BRow,BCol,BTable,BLink,BBadge,BPagination,BButton} from 'bootstrap-vue'
    import taskService from '@core/services/task/useTask';
    import practiceService from "@core/services/practice/usePractice";
    import vSelect from 'vue-select'
    import {$themeConfig} from "@themeConfig";
    import {getUserData} from "@/auth/utils";
    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BTable,
            BLink,
            BBadge,
            BPagination,
            BButton,
            vSelect
        },
        data(){
            let userData = getUserData();
            return {
                practice: '',
                userData: userData,
                catFilter:'',
                practiceFilter:'',
                practices: [],
                tasks: [],
                totalTasks: 0,
                filterOptions: ["Incomplete Profile","Beneficial Owner","File Review"],
                tableColumns: [
                    {key: 'name',label:"Task",sortable: true},
                    {key: 'type',label:"Type",sortable: true},
                    {key: 'assignee.name',label:"Assignee",sortable: true},
                    {key: 'priority',label:"Priority",sortable: true},
                    {key: 'attachment_url',label:"Attachment",sortable: true},
                    {key: 'category',label:"Category",sortable: true},
                    {key: 'due_date_format',label:"Due Date",sortable: true},
                    {key: 'actions',label:"Actions",sortable: false}
                ],
                perPage: 20,
                currentPage: 1,
                searchQuery: ""
            }
        },
        methods: {
            getAllUserTasks(){
                let practice = "";
                let urlParams = new URLSearchParams(window.location.search);
                if(urlParams.has('practice')){
                    practice = urlParams.get('practice');
                }
                if(this.practiceFilter){
                    practice = this.practiceFilter.id;
                }
                taskService.getAllUserTasks({category: this.catFilter,practice: practice}).then(response => {
                    this.tasks = response.data;
                    this.totalTasks = this.tasks.length;
                }).catch(error => {
                    if(error.response.status == 403){
                      window.location = $themeConfig.app.suspendedAccountRedirect;
                    }
                });
            },
            getPractices(){
                practiceService.getAllPractices().then(response => {
                    let selected = response.data.find((x) => {
                        if(x.id == this.practice){
                            return x;
                        }
                    });
                    if(selected){
                        this.practiceFilter = selected;
                    }
                    this.practices = response.data;
                }).catch(error => {
                    if(error.response.status == 403){
                        window.location = $themeConfig.app.suspendedAccountRedirect;
                    }
                });
            },
            handleTaskClick(task){
                if(this.userData.role){
                    if(this.userData.role == "admin" && !task.reviewed){
                        taskService.changeTaskStatus({id: task.id,completed: true,reviewed: true}).then(response => {
                            task.completed = true;
                            task.reviewed = true;
                        });
                    }else if(this.userData.role != "admin" && !task.completed){
                        taskService.changeTaskStatus({id: task.id,completed: true}).then(response => {
                            task.completed = true;
                        });
                    }
                }
            }
        },
        created(){
            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('practice')){
                this.practice = urlParams.get('practice');
            }
        },
        mounted(){
            this.getPractices();
            this.getAllUserTasks();
        }
    }
</script>
<style lang="scss" scoped>
    .per-page-selector{width:90px;}
    .filter-wrap{display:flex;margin-bottom:20px;column-gap:20px;justify-content:flex-end;}
    .filter-wrap .filter{display:flex;min-width:200px;}
    .filter-wrap .filter.w300{display:flex;min-width:300px;}
    .filter-wrap .filter .v-select{flex:1;}
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>